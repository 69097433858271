import React from "react"

const Advertisement = () => (
  <div className="p-4 border rounded my-4">
    <p className="text-xs text-gray-600">
      ADVERTISEMENT <span className="text-xl text-blue-400">&#8883;</span>
    </p>
    <p className="font-medium text-sm">Learn IT courses on your own time</p>
    <p className="text-xs text-gray-700 py-1">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nulla dolores
      tempore iure, in praesentium necessitatibus, ipsum cumque qui sapiente
      porro, tenetur sed. Itaque voluptatem harum ab perferendis culpa neque
      porro!
    </p>
    <a href="/" className="text-blue-600 text-sm">
      Start Learning Now &rarr;
    </a>
  </div>
)

export default Advertisement
