import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layout"
import Sidebar from "./sidebar"
import tocEntries from "../../../lib/tocEntries"
import Pagination from "./pagination"
import MarkdownStyle from "./markdown.module.css"

const ChapterPage = ({
  data: {
    currentPage: {
      html,
      frontmatter: { title, description },
      fields: { course, slug, chapter },
    },
    currentCourse: {
      frontmatter: { toc, title: currentCourseTitle },
    },
    site: { siteMetadata },
    tocPages,
  },
}) => {
  const entries = tocEntries(toc, tocPages)
  const tocWithMatchingEntries = toc.filter(tocItem =>
    entries.find(entry => entry.chapter === tocItem)
  )
  const nextEntry = entries[tocWithMatchingEntries.indexOf(chapter) + 1]
  const firstChapter = tocWithMatchingEntries[0]

  return (
    <Layout>
      <div className="w-full max-w-screen-xl mx-auto">
        <div className="flex flex-wrap -mx-6">
          <div id="sidebar" className="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 px-6">
            <Sidebar
              chapter={chapter}
              entries={entries}
              firstChapter={firstChapter}
              course={course}
              courseTitle={currentCourseTitle}
              slug={slug}
            />
          </div>
          <div
            id="content"
            className="w-full md:w-2/3 lg:w-3/4 xl:w-4/5 pt-10 px-6"
          >
            <div className="mb-8">
              <h1 className="maintitle mb-2">{title}</h1>
              <p className="font-medium text-gray-700">{description}</p>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: html }}
              className={MarkdownStyle.markdown}
            />
            <Pagination nextEntry={nextEntry} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ChapterPage

export const query = graphql`
  query ChapterQuery($slug: String!, $course: String!) {
    currentPage: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
      fields {
        course
        slug
        chapter
      }
    }
    currentCourse: markdownRemark(
      fields: { course: { eq: $course }, pageType: { eq: "course" } }
    ) {
      frontmatter {
        toc
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    tocPages: allMarkdownRemark(
      filter: {
        fields: { course: { eq: $course }, pageType: { eq: "chapter" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            tocTitle
            title
            description
          }
          fields {
            slug
            chapter
          }
        }
      }
    }
  }
`
