import React from "react"
import { Link } from "gatsby"
import Advertisement from "../../basics/advertisement"

const Sidebar = ({
  chapter,
  entries,
  firstChapter,
  course,
  courseTitle,
  slug,
}) => (
  <div className="sticky top-0 overflow-y-scroll pt-10">
    <h2 className="graytitle mb-6 border rounded box-border p-4">
      {courseTitle}
    </h2>
    <ul>
      {entries.map(entry => {
        const isActive = slug === entry.slug

        return (
          <li
            key={entry.slug}
            className={`mb-4 leading-tight hover:text-black active:text-blue-600 ${
              isActive
                ? "font-bold text-blue-600 border-l-4 border-blue-600 pl-2"
                : ""
            }`}
          >
            <Link to={entry.slug}>{entry.title}</Link>
          </li>
        )
      })}
    </ul>
    <Advertisement />
  </div>
)

export default Sidebar
