import React from "react"
import { Link } from "gatsby"

const Pagination = ({ nextEntry }) => {
  // console.log("nextEntry: ", nextEntry)
  return nextEntry ? (
    <div className="py-12">
      <h2 className="font-bold uppercase text-gray-700 tracking-widest mb-4">
        next chapter
      </h2>
      <div className="flex flex-wrap -mx-6">
        <div className="w-full md:w-2/3 px-6">
          <div className="p-6 border rounded-lg shadow-md flex flex-wrap items-center justify-between text-center md:text-left">
            <div>
              <h3 className="font-bold mb-2">{nextEntry.title}</h3>
              <p className="text-sm">{nextEntry.description}</p>
            </div>
            <div className="py-6 mx-auto md:mx-0">
              <Link
                to={nextEntry.slug}
                className="py-3 px-6 font-medium rounded-full bg-blue-600 text-white hover:shadow-xl"
              >
                Next Chapter
              </Link>
            </div>
          </div>
        </div>
        <div className="flex-1 px-6 py-10 md:py-0">
          <div className="px-4 py-1 border rounded">
            <p className="text-xs text-gray-600">
              ADVERTISEMENT{" "}
              <span className="text-xl text-blue-400">&#8883;</span>
            </p>
            <p className="font-medium text-sm">
              Learn IT courses on your own time
            </p>
            <p className="text-xs text-gray-700 py-1">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            </p>
            <a href="/" className="text-blue-600 text-sm">
              Start Learning Now &rarr;
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default Pagination
